import React from "react";
import iosImage from "../images/ios.png";
import samsungImage from "../images/samsung.png";
import androidIosImage from "../images/android_ios.png";
import "../stylesheets/MobileApps.css";

const MobileApps: React.FC = () => {
  return (
    <section id="mobile-development">
      <div className="content-apps">
        <h2>Desarrollo Móvil a la Medida</h2>
        <p>
          En nuestra empresa, nos especializamos en el desarrollo de aplicaciones móviles personalizadas que se adaptan perfectamente a tus necesidades y objetivos empresariales. Ya sea que necesites una aplicación para iOS, Android o ambas plataformas, nuestro equipo de expertos en desarrollo móvil está listo para hacer realidad tu visión.
        </p>
        <img src={androidIosImage} alt="Android and iOS" className="android-ios-image" />
      </div>
      <div className="phone-images">
        <img src={iosImage} alt="iPhone" className="ios-image" />
        <img src={samsungImage} alt="Samsung" className="samsung-image" />
      </div>
    </section>
  );
};

export default MobileApps;
