import React from "react";
import technologyAdviceImage from "../images/idea.png";
import "../stylesheets/TechnologyAdvice.css";

const TechnologyAdvice: React.FC = () => {
  return (
    <section id="technology-advice">
      <div className="image-container">
        <img src={technologyAdviceImage} alt="Technology Advice" className="technology-advice-image" />
      </div>
      <div className="content">
        <h2>Asesoría en Tecnología</h2>
        <p>
          ¿Tienes un negocio y no sabes cómo podrías empezar a utilizar una aplicación? No te preocupes, estamos aquí para ayudarte. Nuestro equipo de expertos en tecnología te brinda asesoría personalizada para identificar las oportunidades y desafíos tecnológicos de tu negocio. Te guiaremos en el proceso de incorporar soluciones tecnológicas que impulsen tu crecimiento y mejoren la eficiencia de tus operaciones.
        </p>
        <p>
          Ya sea que necesites automatizar procesos, mejorar la comunicación con tus clientes o implementar herramientas de análisis de datos, nuestros asesores te brindarán las recomendaciones y estrategias adecuadas para aprovechar al máximo la tecnología en tu negocio. Nos mantenemos actualizados con las últimas tendencias y avances tecnológicos para ofrecerte soluciones innovadoras y adaptadas a tus necesidades específicas.
        </p>
      </div>
    </section>
  );
};

export default TechnologyAdvice;
