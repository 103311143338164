import React, { useState } from "react";
import logo from "../images/logo.png";
import "../stylesheets/Header.css";

const Header: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const scrollToSection = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
    if (menuOpen) {
      setMenuOpen(false);
    }
  };

  return (
    <header className="App-header">
     
      
      <img src={logo} className="App-logo" alt="logo" />
      <nav className={`App-nav ${menuOpen ? "show" : ""}`}>
        <div className="dropdown">
          <button className="dropbtn">Servicios</button>
          <div className="dropdown-content">
            <a className="clickable" onClick={() => scrollToSection("web-development")}>Desarrollo Web</a>
            <a className="clickable" onClick={() => scrollToSection("mobile-development")}>Desarrollo Móvil a la Medida</a>
            <a className="clickable" onClick={() => scrollToSection("technology-advice")}>Asesorías de Tecnología</a>
          </div>
        </div>
        <a className="clickable" onClick={() => scrollToSection("our-work")}>Nuestro Trabajo</a>
        <a className="clickable" onClick={() => scrollToSection("about-us")}>Nosotros</a>
      </nav>
      <button className="contact-button" onClick={() => scrollToSection("contact")}>
        Contáctanos
      </button>
      <button className="hamburger-menu" onClick={toggleMenu}>
        <div></div>
        <div></div>
        <div></div>
      </button>
    </header>
  );
};

export default Header;
