import React from "react";
import webDevelopmentImage from "../images/webpage.png";
import reactImage from "../images/react.png";
import jsImage from "../images/javascript.png";
import laravel from "../images/laravel.png";
import "../stylesheets/WebDevelopment.css";

const WebDevelopment: React.FC = () => {
  return (
    <section id="web-development">
      <div className="image-container">
        <img
          src={webDevelopmentImage}
          alt="Web Development"
          className="web-development-image"
        />
      </div>
      <div className="content">
        <h2>Desarrollo Web Innovador</h2>
        <p>
          Nuestro equipo de desarrollo web se dedica a crear sitios web y
          aplicaciones web excepcionales que impulsan tu presencia en línea.
          Utilizamos las últimas tecnologías y frameworks para desarrollar
          soluciones web a medida que se adaptan perfectamente a tus necesidades
          empresariales. Ya sea que necesites un sitio web informativo, una
          tienda en línea o una aplicación web interactiva, estamos aquí para
          ayudarte a alcanzar tus objetivos digitales.
        </p>
        <div className="development-images">
          <img
            src={reactImage}
            alt="Responsive Web Design"
            className="responsive-image"
          />
          <img
            src={jsImage}
            alt="Responsive Web Design"
            className="responsive-image"
          />
          <img
            src={laravel}
            alt="Responsive Web Design"
            className="responsive-image"
          />
        </div>
      </div>
    </section>
  );
};

export default WebDevelopment;
