import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import emailjs from "emailjs-com";
import "../stylesheets/Contact.css";

const Contact: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const sendEmail = (e: React.FormEvent) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_921kfzj",
        "template_2o9w4ve",
        e.target as HTMLFormElement,
        "ROAOnFSnhhaygeSp7"
      )
      .then(
        (result) => {
          setShowModal(true);
          if (formRef.current) {
            formRef.current.reset();
          }
          window.scrollTo(0, 0);
        },
        (error) => {
          alert("Error al enviar el mensaje.");
        }
      );
  };

  return (
    <section id="contact" className="contact">
      <h2>Contacto</h2>
      <form ref={formRef} onSubmit={sendEmail}>
        <label>
          Nombre:
          <input type="text" name="name" required />
        </label>
        <label>
          Correo Electrónico:
          <input type="email" name="email" required />
        </label>
        <label>
          Número de Teléfono:
          <input
            type="tel"
            name="phone"
            required
            pattern="\d{10,}"
            title="Ingrese un número de teléfono válido con al menos 10 dígitos"
          />
        </label>
        <div className="contact-preference">
          <div className="contact-preference-title">
            Preferencia de Contacto:
          </div>
          <label className="radio-option">
            <input
              type="radio"
              name="contactPreference"
              value="call"
              required
            />
            <span>Llamada</span>
          </label>
          <label className="radio-option">
            <input
              type="radio"
              name="contactPreference"
              value="whatsapp"
              required
            />
            <span>WhatsApp</span>
          </label>
        </div>
        <label className="message-label">
          Mensaje:
          <textarea name="message" required></textarea>
          <div className="info-text">
            <FontAwesomeIcon icon={faInfoCircle} className="info-icon" />
            <span>
              Describa brevemente para qué tipo de negocio sería la aplicación,
              si quiere una app móvil o web, o cuál es su idea.
            </span>
          </div>
        </label>
        <button type="submit" className="contact-button">
          Enviar
        </button>
      </form>
      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>¡Mensaje Enviado!</h3>
            <p>
              Gracias por tu mensaje. Nos pondremos en contacto contigo pronto.
            </p>
            <button onClick={() => setShowModal(false)} className="modal-close">
              Cerrar
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default Contact;
