import React from "react";
import appExample from "../images/appsexample.png";
import companyLogo1 from "../images/android_phone.png"; // Nueva imagen a la izquierda
import companyLogo2 from "../images/ios_phone.png"; // Nueva imagen a la derecha
import "../stylesheets/OurWork.css";

const OurWork: React.FC = () => {
  return (
    <section id="our-work">
      <div className="our-work-container">
        <div className="image-container-our-work">
          <img src={companyLogo1} alt="Company 1" className="our-work-image1" />
        </div>
        <div className="content-our-work">
          <h2>Nuestro Trabajo</h2>
          <p>
            En nuestra empresa, hemos tenido el privilegio de trabajar con
            múltiples pequeñas y medianas empresas que buscan mejorar su negocio y
            llegar a más clientes. Nos enorgullece ser su socio estratégico en el
            mundo digital, ayudándoles a alcanzar sus objetivos y facilitar su
            trabajo diario.
          </p>
          <p>
            Nuestro enfoque personalizado nos permite entender las necesidades
            únicas de cada cliente y desarrollar soluciones tecnológicas a medida.
            Ya sea que necesiten un sitio web atractivo y funcional, una
            aplicación móvil innovadora o asesoría en tecnología, nuestro equipo
            de expertos está listo para enfrentar cualquier desafío.
          </p>
          <p>
            Nos apasiona ver cómo nuestro trabajo tiene un impacto positivo en el
            crecimiento y éxito de nuestros clientes. Desde aumentar su presencia
            en línea hasta optimizar sus procesos internos, nos dedicamos a
            brindarles las herramientas y el apoyo necesarios para destacarse en
            su industria.
          </p>
          <p>Algunos de nuestros proyectos destacados incluyen:</p>
          <ul>
            <li>
              Desarrollo de una aplicación móvil de comercio electrónico para una
              tienda minorista, aumentando sus ventas en un 30%.
            </li>
            <li>
              Creación de un sistema de gestión de inventario personalizado para
              una empresa manufacturera, mejorando la eficiencia en un 25%.
            </li>
            <li>
              Diseño y desarrollo de un sitio web interactivo para una
              organización sin fines de lucro, lo que resultó en un aumento del
              50% en donaciones.
            </li>
          </ul>
          <p>
            Nos enorgullece formar parte del viaje de crecimiento de nuestros
            clientes y estamos comprometidos a seguir brindando soluciones
            tecnológicas excepcionales que impulsen su éxito.
          </p>
          <div className="divider">
            <div className="companies-logos">
              <img src={appExample} alt="Our Work" className="company-logo" />
            </div>
          </div>
        </div>
        <div className="image-container-our-work">
          <img src={companyLogo2} alt="Company 2" className="our-work-image2" />
        </div>
      </div>
    </section>
  );
};

export default OurWork;
