import React from "react";
import "./App.css";

import Header from "./components/Header";
import InfoSection from "./components/InfoSection";
import Banner from "./components/Banner";
import MobileApps from "./components/MobileApps";
import WebDevelopment from "./components/WebDevelopment";
import TechnologyAdvice from "./components/TechnologyAdvice";
import OurWork from "./components/OurWork";
import Contact from "./components/Contact";
import AboutUs from "./components/AboutUs";

const App: React.FC = () => {
  return (
    <div className="App">
      <Header />
      <Banner />
      <InfoSection />
      <MobileApps />
      <WebDevelopment />
      <TechnologyAdvice />
      <OurWork />
      <AboutUs />
      <Contact />
    </div>
  );
};

export default App;
