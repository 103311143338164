import React from "react";
import "../stylesheets/AboutUs.css";
import teamImage from "../images/chihuahua.jpeg";

const AboutUs: React.FC = () => {
  return (
    <section id="about-us" className="about-us">
      <div className="about-us-content">
        <h2>Sobre Nosotros</h2>
        <p>
          En WolvesLabs, nos enorgullece contar con una trayectoria que comenzó
          en 2018, originarios de Chihuahua, México. Somos un equipo apasionado
          y altamente capacitado, comprometido en brindar soluciones
          tecnológicas a medida para nuestros clientes. Nuestra visión es clara:
          aspiramos a convertirnos en líderes en el sector tecnológico a nivel
          local y, eventualmente, a nivel nacional.
        </p>
        <p>
          Desde nuestros inicios, hemos estado enfocados en entender y
          satisfacer las necesidades únicas de cada cliente. Cada proyecto que
          emprendemos es una oportunidad para demostrar nuestro compromiso con
          la excelencia y la innovación. Nos dedicamos a ofrecer soluciones
          personalizadas que no solo cumplen, sino que superan las expectativas
          de nuestros clientes.
        </p>
        <p>
          En WolvesLabs, cada miembro de nuestro equipo está listo para
          enfrentar nuevos desafíos y aportar su experiencia y creatividad para
          desarrollar soluciones efectivas. Creemos en la colaboración y en el
          trabajo en equipo para alcanzar los mejores resultados. Nuestro
          objetivo es proporcionar a nuestros clientes las herramientas
          necesarias para destacar en su industria y alcanzar el éxito.
        </p>
        <p>
          Estamos comprometidos a ser un socio estratégico en su camino hacia el
          crecimiento y la transformación digital. Con cada proyecto,
          reafirmamos nuestro compromiso de ofrecer soluciones tecnológicas de
          vanguardia que impulsen el éxito de nuestros clientes y nos acerquen a
          nuestra visión de ser líderes en tecnología.
        </p>
      </div>
      <div className="about-us-image">
        <img src={teamImage} alt="Equipo de WolvesLabs" />
      </div>
    </section>
  );
};

export default AboutUs;
