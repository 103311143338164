import React from 'react';
import disenio from "../images/disenio.png";
import flecha from "../images/flecha.png";
import desarrollo from "../images/desarrollo.png";
import publicacion from "../images/publicacion.png";
import soporte from "../images/soporte.png";
import "../stylesheets/InfoSection.css";

const InfoSection: React.FC = () => {
  return (
    <section id="info-section">
      <div className="info-section">
        <h1>Haz crecer tu negocio con tu propia aplicación</h1>
        <div>
          <p className="info-section-description">
            No solo diseñamos y construimos tu App a medida, sino que nos
            encargamos también del proceso de publicación y distribución en
            Google Play y App Store. Pero ¿de qué sirve tener la mejor App si
            tus clientes no se la descargan? Por este motivo, y para asegurar
            el éxito de tu proyecto, todas nuestras aplicaciones incluyen un
            plan estratégico de lanzamiento y asesoramiento en marketing móvil
            sin coste.
          </p>
        </div>
      </div>
      <div className="develop-section">
        <div className="image-container">
          <img src={disenio} alt="disenio" />
          <p className="image-description">
            Diseñamos cada pantalla, cada botón y cada elemento gráfico de tu
            App para llevar tu negocio al siguiente nivel.
          </p>
        </div>
        <div>
          <img className="flecha" src={flecha} alt="flecha 1" />
          <p className="image-empty-description">
            <br />
            <br />
            <br />
          </p>
        </div>
        <div className="image-container">
          <img src={desarrollo} alt="Brand 3" />
          <p className="image-description">
            Usamos los frameworks que mejor se adaptan a las necesidades de tu
            proyecto.
          </p>
        </div>
        <div>
          <img className="flecha" src={flecha} alt="flecha 2" />
          <p className="image-empty-description">
            <br />
            <br />
            <br />
          </p>
        </div>
        <div className="image-container">
          <img src={publicacion} alt="publicacion" />
          <p className="image-description">
            Nos encargamos del proceso de distribución con Google y Apple y
            realizamos las revisiones pertinentes.
          </p>
        </div>
        <div>
          <img className="flecha" src={flecha} alt="flecha 3" />
          <p className="image-empty-description">
            <br />
            <br />
            <br />
          </p>
        </div>
        <div className="image-container">
          <img src={soporte} alt="soporte" />
          <p className="image-description">
            Soporte y mantenimiento
            <br />
            continuo para el éxito.
          </p>
        </div>
      </div>
    </section>
  );
};

export default InfoSection;
