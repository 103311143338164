import React from "react";
import bannerImage from "../images/banner-image.png";
import brand1 from "../images/brand1.png";
import brand2 from "../images/brand2.png";
import brand3 from "../images/brand3.png";
import brand4 from "../images/brand4.jpg";
import brand5 from "../images/brand5.png";
import "../stylesheets/Banner.css"; 

const Banner: React.FC = () => {
  return (
    <>
      <div className="banner">
        <div className="banner-text">
          <h1>Transformamos tus ideas en proyectos reales</h1>
          <p>
            En WolvesLabs, convertimos tus conceptos en soluciones funcionales y
            efectivas. Nuestro equipo de expertos está aquí para llevar tu
            visión a la realidad con aplicaciones web y móviles personalizadas.
            ¡Hablemos de cómo podemos ayudarte a alcanzar tus objetivos!
          </p>
        </div>
        <div className="banner-image">
          <img src={bannerImage} alt="Banner" />
        </div>
      </div>
      <div className="brands-section">
        <h2>Empresas que confían en nosotros</h2>
        <div className="brands-logos">
          <img src={brand1} alt="Brand 1" />
          <img src={brand2} alt="Brand 2" />
          <img src={brand3} alt="Brand 3" />
          <img src={brand4} alt="Brand 4" />
          <img src={brand5} alt="Brand 5" />
        </div>
      </div>
    </>
  );
};

export default Banner;
